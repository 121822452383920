<template>
    <div class="home-page">
        <div class="banner">
            <div class="img-warp">
                <img src="https://oss.nuohuipay.cn/ClientProject/guangfubao/images/1653883223877.png" alt="" />
            </div>
        </div>

        <section class="home-content" id="homeContent">
            <div class="insure-card" ref="insure">
                <div class="right-card">
                    <!-- <div class="total-money">
                        <span class="money">99</span>
                        <span class="unit">元/人</span>
                    </div> -->
                    <div class="submit-btn" @click="$router.push('/tianjin/insure_page')">
                        立即投保
                    </div>
                </div>
            </div>
            <div class="scroll-card">
                <div class="sticky-card">
                    <nav class="nav-list">
                        <div class="nav-item" :class="navIndex == 1 ? 'active' : ''" @click="handleChangeNav(1, '#insureder')">
                            <div>保障内容</div>
                            <span class="badge"></span>
                        </div>
                        <div class="nav-item" :class="navIndex == 2 ? 'active' : ''" @click="handleChangeNav(2, '#product')">
                            <div>参保流程</div>
                            <span class="badge"></span>
                        </div>
                        <div class="nav-item" :class="navIndex == 3 ? 'active' : ''" @click="handleChangeNav(3, '#claimCases')">
                            <div>常见问题</div>
                            <span class="badge"></span>
                        </div>
                        <div class="nav-item" :class="navIndex == 4 ? 'active' : ''" @click="handleChangeNav(4, '#question')">
                            <div>理赔案例</div>
                            <span class="badge"></span>
                        </div>
                    </nav>
                    <div class="right-desc" v-show="buttonFlag">
                        <div class="total-money">
                            <span class="money">189</span>
                            <span class="unit">元/人</span>
                        </div>
                        <div class="submit-btn" @click="$router.push('/tianjin/insure_page')">
                            立即投保
                        </div>
                    </div>
                </div>
                <header class="common-header" id="insureder">
                    <div class="title pad-left">
                        <span>保障内容</span>
                    </div>
                </header>
                <div class="content-img">
                    <img src="https://oss.nuohuipay.cn/ClientProject/guangfubao/images/1653883333138.png" alt="" />
                    <div class="tips">
                        <img src="https://oss.nuohuipay.cn/ClientProject/guangfubao/images/1646291498169.png" alt="" />
                        备注：未尽事宜，请以
                        <span>《参保须知》</span>
                        和
                        <span>《理赔须知》</span>
                        为准
                    </div>
                </div>
                <div class="content-button">
                    <div class="detail-button">
                        <span class="button-text" @click="handleShow">查看保障详情</span>
                        <img class="button-img" src="https://oss.nuohuipay.cn/ClientProject/guangfubao/images/1646028321794.png" alt="" />
                    </div>
                </div>

                <header class="common-header" style="padding: 56px 0 10px" id="product">
                    <div class="title pad-left">
                        <span>参保流程</span>
                    </div>
                </header>
                <div class="progress">
                    <p>只需三步即可完成投保</p>
                    <img src="https://oss.nuohuipay.cn/ClientProject/guangfubao/images/1652781479086.png" alt="" />
                </div>

                <header class="common-header" style="padding: 56px 0 0" id="claimCases">
                    <div class="title pad-left">
                        <span>常见问题</span>
                    </div>
                </header>

                <div class="question">
                    <el-collapse accordion>
                        <el-collapse-item v-for="(item, index) of question" :key="index">
                            <template slot="title">
                                <div class="collapse-title">
                                    <img src="https://oss.nuohuipay.cn/ClientProject/guangfubao/images/1646044614662.png" alt="" />
                                    <span>{{ item.title }}</span>
                                </div>
                            </template>
                            <div class="collapse-content">
                                <img class="article-img" src="https://oss.nuohuipay.cn/ClientProject/guangfubao/images/1646044614662.png" alt="" />
                                <div class="article">{{ item.desc }}</div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div>

                <div class="content-button" style="margin-top: 44px">
                    <div class="detail-button" @click="show = true">
                        <span class="button-text">查看更多问题</span>
                        <img class="button-img" src="https://oss.nuohuipay.cn/ClientProject/guangfubao/images/1646028321794.png" alt="" />
                    </div>
                </div>

                <header class="common-header" style="padding: 56px 0 0" id="question">
                    <div class="title pad-left">
                        <span>理赔案例</span>
                    </div>
                </header>
                <div class="example">
                    <p>仅做演示使用，不作为理赔依据</p>
                    <div class="example-list">
                        <img src="https://oss.nuohuipay.cn/ClientProject/guangfubao/images/1653919306175.png" alt="" />
                        <img src="https://oss.nuohuipay.cn/ClientProject/guangfubao/images/1653919308226.png" alt="" />

                        <img src="https://oss.nuohuipay.cn/ClientProject/guangfubao/images/1653919310726.png" alt="" />
                    </div>
                </div>
            </div>
        </section>

        <footer class="footer">
            <img class="footer-bg" src="https://oss.nuohuipay.cn/ClientProject/guangfubao/images/1653966525850.png" alt="">
            <img class="footer-content" src="https://oss.nuohuipay.cn/ClientProject/guangfubao/images/1653966423886.png" alt="" />
            <!-- <div class="img-warp">
                <img src="https://oss.nuohuipay.cn/ClientProject/guangfubao/images/1650437326486.png" alt="">
            </div> -->
        </footer>

        <!-- 查看常见问题 -->
        <el-dialog :visible.sync="show" width="900px" append-to-body :show-close="false">
            <div class="dialog-content">
                <header class="dialog-header">
                    <div class="title">
                        <span>常见问题</span>
                        <img class="del" @click="show = false" src="https://oss.nuohuipay.cn/ClientProject/nuohuifu/images/1646295309622.png" alt="" />
                    </div>
                </header>
                <div class="question-list">
                    <el-collapse accordion>
                        <el-collapse-item v-for="(item, index) of common_data" :key="index">
                            <template slot="title">
                                <div class="collapse-title">
                                    <img src="https://oss.nuohuipay.cn/ClientProject/guangfubao/images/1646044614662.png" alt="" />
                                    <span>{{ item.title }}</span>
                                </div>
                            </template>
                            <div class="collapse-content">
                                <img class="article-img" src="https://oss.nuohuipay.cn/ClientProject/guangfubao/images/1646044614662.png" alt="" />
                                <div class="article" v-html="item.text"></div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "index",
    data() {
        return {
            navIndex: 1,
            marginTop: "",
            question: [
                {
                    id: 1,
                    title: "什么是人民普惠保（天津）？",
                    desc:
                        "人民普惠保（天津）是在津保险公司自愿参与共同承保的，依托 “一单式”结算体系，根据天津市居民的医疗和生活水平，紧密衔接社会医疗保险的一款商业医疗保险产品，以亲民的价格为具有参保资格人员提供百万级保险保障和便民化理赔及健康服务，提升天津居民的健康保障水平。",
                },
                {
                    id: 2,
                    title: "哪些人可以参保“人民普惠保（天津）”？",
                    desc:
                        "天津市基本医疗保险的在保人员，均可参保“人民普惠保（天津）”",
                },
                {
                    id: 3,
                    title: "可以为家人购买吗？",
                    desc:
                        "可以。",
                },
                {
                    id: 4,
                    title: "参保时需要体检吗？",
                    desc: "不需要体检。",
                },
                {
                    id: 5,
                    title: "参保人年龄对保费有影响吗？",
                    desc:
                        "没有影响。",
                },
                {
                    id: 6,
                    title: "购买了“人民普惠保（天津）”，何时生效？",
                    desc: "在集中参保期投保（2022年6月5日-2022年7月31），保障生效期为2022年8月1日。保险期限为2022年 8月1日零时至2023年7月31日二十四时。",
                },
            ],
            common_data: [
                {
                    title: "“人民普惠保（天津）”的投保期和保障期是？	",
                    text:
                        "投保期：2022年6月5日-2022年7月31日。投保期截止后不可再投保。" +
                        "<br/>" +
                        "保障期：2022年8月1日零时起-2023年7月31日二十四时止。",
                },
                {
                    title: "什么是人民普惠保（天津）？",
                    text:
                        "人民普惠保（天津）是在津保险公司自愿参与共同承保的，依托 “一单式”结算体系，根据天津市居民的医疗和生活水平，紧密衔接社会医疗保险的一款商业医疗保险产品，以亲民的价格为具有参保资格人员提供百万级保险保障和便民理赔及健康服务，提升天津居民的健康保障水平。",
                },
                {
                    title: "哪些人可以参保“人民普惠保（天津）”？",
                    text:
                        "天津市基本医疗保险的在保人员，均可参保“人民普惠保（天津）”。",
                },
                {
                    title: "可以为家人投保吗？",
                    text:
                        "可以。",
                },
                {
                    title: "参保人年龄对保费是否有影响？",
                    text:
                        "没有影响。",
                },
                {
                    title: "未成年人可以参保“人民普惠保（天津）”吗？",
                    text:
                        "可以，被保险人是未成年人（新生儿需≥30 天）时，投保人必须为被保险人的法定监护人，且未成年人已参加天津市基本医疗保险。",
                },
                {
                    title: "参保时需要体检吗？",
                    text: "不需要体检。",
                },
                {
                    title: "我生过病，可以买吗？",
                    text:
                        "可以投保，无需体检。全体参加天津市基本医疗保险的在保人员，均可参保该项目。本产品承担一般既往症，但初次投保保单生效前（2022年8月1日前）或非连续投保客户在重新投保前已罹患恶性肿瘤（含白血病、淋巴瘤），并因该疾病或其并发症导致的住院医疗费用及特定高额药品费用，本产品不予赔付。请您在投保前详细阅读《参保须知及声明》。",
                },
                {
                    title: "投保有户籍限制吗，不是天津市的户籍可以买吗？",
                    text: "本产品不限户籍。天津市基本医保在保人员都可购买。",
                },
                {
                    title:
                        "天津市职工医保退休人员已经不需要再缴纳基本医疗保险费用，是否可以参保“人民普惠保（天津）”？",
                    text: "可以参保。",
                },
                {
                    title: "有医保但不是天津市医保，可以参保吗？",
                    text:
                        "不可以参保，“人民普惠保（天津）”的参保人仅限天津市基本医保在保人员。",
                },
                {
                    title: "已经有国家基本医疗保险，还有必要购买“人民普惠保（天津）”吗？",
                    text:
                        "有必要。“人民普惠保（天津）”是为天津基本医保参保人，准备的一款依托“一单式”结算体系配套的商业补充医疗保险产品。“人民普惠保（天津）”对经医保目录范围内和大病医疗报销后个人自付金额，以及在医保目录范围外的医保不予报销的个人自费金额，超过免赔额以上的部分，会按照对应报销比例予以报销。尤其在重特大疾病医疗费用方面，可有效缓解医保报销后个人承担的医疗费用，有效防止因病致贫因病返贫。",
                },
                {
                    title: "“人民普惠保（天津）”有等待期吗？",
                    text: "没有等待期",
                },
                {
                    title: "免赔额是什么意思？",
                    text:
                        "免赔额是指，一个保险期间内保单载明的保险责任范围内，被保险人需要自行承担且保险公司不负责赔偿的额度。",
                },
                {
                    title: "我可以给自己买2份，获得两份保障吗？",
                    text: "不可以，三款保障任选其一，本产品同一被保险人在一个保单年度，只能购买一份。",
                },
                {
                    title: "“人民普惠保（天津）”有赔付次数的限制吗？",
                    text:
                        "在保险期间符合“人民普惠保（天津）”赔付范围的费用，在年度限额内可多次理赔，没有理赔次数限制，直至单个保险责任达到赔偿金额上限为止。",
                },
                {
                    title: "是被保险人申请理赔还是投保人申请理赔？",
                    text: "被保险人申请理赔；当被保险人为未成年人时由其投保人（监护人）为其申请理赔。",
                },
                // {
                //   title: "未参保天津市城镇职工大病医疗保险的，但触发大病医疗保险的，如何理赔？",
                //   text:
                //     "未参保天津市城镇职工大病医疗保险，但触发大病医疗保险的，大病医保险起付线以上的部分在扣除责任免除的医疗费用及年免赔额后的剩余部分，年度总累计责任赔偿限额为15万元，对未参加职工大病医保的，本合同仅保障疾病住院医疗及特殊门诊医疗部分（不含特药保障），赔付比例30%",
                // },
                {
                    title: "如果在外地医院看病，除了医保报销部分，其他部分是否还可以报销？",
                    text:
                        "在天津市外医疗机构（仅限中国大陆境内的社会医疗保险定点医院）就医，可正常申请理赔；在非社会医疗保险定点医院所产生的费用均不予报销。",
                },
                {
                    title: "“人民普惠保（天津）”，明年可以参保吗？",
                    text:
                        "请持续关注“人民普惠保 天津”微信公众号，并以“人民普惠保 天津”公众号公示的信息和通知为准。",
                },
            ],
            show: false,
            buttonFlag: false,
        };
    },
    mounted() {
        if (this.$route.query) {
            this.$route.query.pid && localStorage.setItem("pid", this.$route.query.pid);
            this.$route.query.cid && localStorage.setItem("cid", this.$route.query.cid);
            this.$route.query.smid && localStorage.setItem("smid", this.$route.query.smid);
        }
        this.$nextTick(() => {
            let that = this;
            document.addEventListener(
                "scroll",
                that.debunce(() => {
                    if (document.getElementById("homeContent")) {
                        let insurederTop =
                            document.getElementById("insureder").offsetTop +
                            document.getElementById("homeContent").offsetTop;
                        let productTops =
                            document.getElementById("product").offsetTop +
                            document.getElementById("homeContent").offsetTop;
                        let claimCasesTop =
                            document.getElementById("claimCases").offsetTop +
                            document.getElementById("homeContent").offsetTop;
                        let questionTop =
                            document.getElementById("question").offsetTop +
                            document.getElementById("homeContent").offsetTop;
                        let top = 0;
                        if (document.documentElement) {
                            top = document.documentElement.scrollTop;
                        } else {
                            top = document.body.scrollTop;
                        }
                        if (top >= insurederTop - 80) {
                            that.buttonFlag = true;
                        } else {
                            that.buttonFlag = false;
                        }
                        if (top >= insurederTop && top < productTops - 100) {
                            that.navIndex = 1;
                        }
                        if (top >= productTops - 100 && top < claimCasesTop - 100) {
                            that.navIndex = 2;
                        }
                        if (top >= claimCasesTop - 100 && top < questionTop - 100) {
                            that.navIndex = 3;
                        }
                        if (top >= questionTop - 100) {
                            that.navIndex = 4;
                        }
                    }
                }, 100)
            );
        });
    },
    beforeDestroy() {
        let that = this;
        document.removeEventListener(
            "scroll",
            this.debunce(() => { }, 100)
        );
    },
    methods: {
        handleChangeNav(index, idName) {
            this.navIndex = index;
            document
                .querySelector(idName)
                .scrollIntoView({ block: "start", behavior: "smooth" });
        },
        // 查看保障详情
        handleShow() {
            window.open(
                "https://oss.nuohuipay.cn/ClientProject/guangfubao/images/1654075101947.pdf"
            );
        },
        debunce(callback, date = 2000) {
            let timer = null;
            return function () {
                if (timer) {
                    clearTimeout(timer);
                    timer = null;
                }
                timer = setTimeout(callback, date);
            };
        },
    },
};
</script>

<style lang="less" scoped>
/deep/ .el-collapse-item__header {
    height: 96px;
}
.pad-left {
    padding-left: 44px;
}
.dialog-content {
    padding-bottom: 10px;
    .dialog-header {
        padding: 20px 0;
        .title {
            padding: 0 44px;
            overflow: hidden;
            position: relative;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            &::before {
                content: "";
                width: 7px;
                height: 28px;
                background: #a72c1c;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
            span:nth-child(1) {
                color: #333333;
                font-size: 29px;
                line-height: 29px;
                font-weight: 600;
            }
            .del {
                cursor: pointer;
                width: 24px;
                height: 24px;
            }
        }
    }
    .question-list {
        height: 530px;
        overflow-y: auto;
        margin: 25px 44px 0;
        .collapse-title {
            display: flex;
            align-items: center;
            img {
                width: 24px;
                height: 24px;
                position: relative;
                top: 2px;
            }
            span {
                margin-left: 22px;
                font-size: 20px;
                font-weight: 600;
                color: #333333;
            }
        }
        .collapse-content {
            display: flex;
            .article-img {
                width: 24px;
                height: 24px;
                position: relative;
                top: 2px;
            }
            .article {
                margin-left: 22px;
                font-size: 20px;
                font-weight: 400;
                color: #999999;
            }
        }
    }
}
.home-page {
    min-height: calc(100vh - 80px - 60px);
    background: #f4f4f4;
    display: flex;
    flex-direction: column;
    align-items: center;
    .banner {
        width: 100%;
        min-width: 1200px;
        max-width: 1920px;
        // overflow: hidden;
        height: 529px;
        margin: 0 auto;
        position: relative;
        .img-warp {
            width: 1200px;
            margin: 0 auto;
            position: relative;
            img {
                width: 1750px;
                position: absolute;
                left: -278px;
            }
        }
    }
    .home-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        z-index: 1;
        margin-top: 120px;
    }
    .insure-card {
        width: 1200px;
        height: 370px;
        background: #ffffff;
        box-shadow: 0px 1px 14px 0px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        background:url(https://oss.nuohuipay.cn/ClientProject/guangfubao/images/1656582993601.png)
            no-repeat;
            
        background-size: 100% 100%;
        background-position: 0 0;
        position: relative;
        .right-card {
            position: absolute;
            width: 223px;
            right: 44px;
            bottom: 33px;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-sizing: border-box;
        }
        .total-money {
            display: flex;
            align-items: flex-end;
            .money {
                font-size: 53px;
                font-weight: 800;
                color: #a72c1c;
                line-height: 43px;
            }
            .unit {
                font-size: 29px;
                font-weight: 500;
                color: #a72c1c;
                line-height: 29px;
            }
        }
        .submit-btn {
            margin-top: 20px;
            cursor: pointer;
            width: 233px;
            height: 56px;
            background: linear-gradient(180deg, #c44534 0%, #a72c1c 100%);
            border-radius: 4px;
            font-size: 27px;
            font-weight: 600;
            color: #ffffff;
            line-height: 56px;
            text-align: center;
        }
    }
    .scroll-card {
        margin-top: 44px;
        width: 1200px;
        background: #ffffff;
        box-shadow: 0px 3px 27px 0px rgba(0, 0, 0, 0.1);
        border-radius: 5px;

        .sticky-card {
            position: sticky;
            top: 80px;
            z-index: 99;
            left: 0;
            right: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #ffffff;
            box-shadow: 0px 1px 0px 0px rgba(216, 216, 216, 1),
                inset 0px 1px 0px 0px rgba(226, 226, 226, 1);

            .right-desc {
                display: flex;
                align-items: center;
                padding-right: 20px;
            }
            .submit-btn {
                cursor: pointer;
                margin-left: 15px;
                width: 200px;
                height: 44px;
                background: linear-gradient(180deg, #c44534 0%, #a72c1c 100%);
                border-radius: 3px;
                text-align: center;
                font-size: 22px;
                font-weight: 600;
                color: #ffffff;
                line-height: 44px;
            }
            .total-money {
                display: flex;
                align-items: flex-end;
                .money {
                    font-size: 51px;
                    font-weight: 800;
                    color: #a72c1c;
                    line-height: 45px;
                    position: relative;
                    top: 3px;
                }
                .unit {
                    margin-left: 2px;
                    font-size: 27px;
                    font-weight: 800;
                    color: #a72c1c;
                    line-height: 27px;
                }
            }
        }
        .nav-list {
            // background: #ffffff;
            // box-shadow: 0px 1px 0px 0px rgba(216, 216, 216, 1);
            display: flex;
            .nav-item:nth-child(1) {
                margin-left: 33px;
            }
            .nav-item {
                cursor: pointer;
                margin-left: 44px;
                position: relative;
                height: 67px;
                line-height: 67px;
                text-align: center;
                div {
                    font-size: 20px;
                    font-weight: 400;
                    color: #999999;
                }
                .badge {
                    position: absolute;
                    width: 10px;
                    height: 3px;
                    background: transparent;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    transition: all 0.3s;
                }
            }
            .nav-item.active {
                div {
                    font-weight: 600;
                    color: #a72c1c;
                }
                .badge {
                    width: 54px;
                    background: #a72c1c;
                }
            }
        }
        .common-header {
            margin-left: 33px;
            padding: 24px 0 20px;
            .title {
                overflow: hidden;
                position: relative;
                display: flex;
                align-items: flex-end;
                &::before {
                    content: "";
                    width: 7px;
                    height: 25px;
                    background: #a72c1c;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
                span:nth-child(1) {
                    color: #333333;
                    font-size: 29px;
                    line-height: 29px;
                    font-weight: 600;
                }
            }
        }
        .content-img {
            padding: 0 33px 0;
            & > img {
                width: 100%;
            }
            .tips {
                padding-top: 20px;
                font-size: 20px;
                font-weight: 400;
                color: #999999;
                display: flex;
                align-items: center;

                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                }
                span {
                    color: #a72c1c;
                }
            }
        }
        .content-button {
            margin-top: 33px;
            display: flex;
            justify-content: center;
            .detail-button {
                cursor: pointer;
                width: 289px;
                height: 67px;
                border-radius: 47px;
                border: 2px solid #a72c1c;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                .button-text {
                    font-size: 22px;
                    font-weight: 400;
                    color: #a72c1c;
                }
                .button-img {
                    position: absolute;
                    width: 26px;
                    height: 20px;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 33px;
                }
            }
        }
        .progress {
            margin: 11px 78px 0;
            p {
                font-size: 20px;
                font-weight: 400;
                color: #999999;
            }
            img {
                margin-top: 33px;
                width: 100%;
                height: 330px;
            }
        }
        .question {
            margin: 25px 78px 0;
            .collapse-title {
                display: flex;
                align-items: center;

                img {
                    width: 22px;
                    height: 22px;
                    position: relative;
                    top: 2px;
                }
                span {
                    margin-left: 22px;
                    font-size: 20px;
                    font-weight: 600;
                    color: #333333;
                }
            }
            .collapse-content {
                display: flex;
                .article-img {
                    width: 22px;
                    height: 22px;
                    position: relative;
                    top: 6px;
                }
                .article {
                    margin-left: 22px;
                    font-size: 20px;
                    font-weight: 400;
                    color: #999999;
                }
            }
        }
        .example {
            padding: 10px 78px 36px;
            p {
                font-size: 20px;
                font-weight: 400;
                color: #999999;
            }
            .example-list {
                margin-top: 44px;
                display: flex;
                overflow-x: auto;
                img {
                    margin-left: 31px;
                    width: 442px;
                    height: 1078px;
                }
                img:nth-child(1) {
                    margin-left: 0;
                }

                &::-webkit-scrollbar {
                    width: 5px;
                    height: 10px;
                }
                &::-webkit-scrollbar-track {
                    background: #fff;
                    border-radius: 2px;
                }
                &::-webkit-scrollbar-thumb {
                    background: #a72c1c;
                    border-radius: 10px;
                }
                &::-webkit-scrollbar-thumb:hover {
                    background: #a72c1c;
                }
                &::-webkit-scrollbar-corner {
                    background: #a72c1c;
                }
            }
        }
    }
    .footer {
        margin-top: 21px;
        display: flex;
        height: 373px;
        position: relative;

        .footer-bg {
            padding: 0 292px;
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 900px;
        }
        .footer-content {
            width: 100%;
        }
        .img-warp {
            width: 1200px;
            margin: 0 auto;
            position: relative;
            img {
                width: 1920px;
                position: absolute;
                left: -360px;
            }
        }
    }
}
/deep/ .el-collapse {
    border-top: 0;
}
</style>
